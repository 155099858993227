
<template>

  <Modal :options="{width: '60vw'}" @close="cancelar">
    <div class="title h2 text-white">{{ !domicilio.id ? 'Agregar' : 'Editar' }} dirección</div>

    <div class="text-center my-4">
      <h5>Ingresa los datos de la dirección.</h5>
    </div>

    <div class="body">

      <div class="alert alert-primary mb-5" role="alert">
        <i class="fa fa-info-circle mr-sm-1"></i> <b>Importante:</b> Los campos que contengan el icono <i class="fa fa-check-circle mx-sm-1"></i> indican que el campo es obligatorio.
      </div>

      <div class="row justify-content-center">
        <div class="col-sm-3 form-group">
          <label for="estado" class="form-label text-truncate d-block" title="Estado">
            <i class="fa fa-check-circle text-primary"></i>
            Estado
          </label>
          <select class="form-control" name="estado" id="estado"  v-model="domicilio.estado">
            <option :value="estado.estado" v-for="estado in estados">{{estado.estado}}</option>
          </select>
        </div>

        <div class="col-sm-3 form-group">
          <label for="municipio" class="form-label text-truncate d-block" title="Alcaldía/Municipio">
            <i class="fa fa-check-circle text-primary"></i>
            Alcaldía/Municipio
          </label>
          <select  class="form-control" name="municipio" id="municipio"  v-model="domicilio.municipio" >
            <option :value="municipio.municipio" v-for="municipio in municipios">{{municipio.municipio}}</option>
          </select>
        </div>

        <div class="col-sm-3 form-group">
          <label for="cp" class="form-label text-truncate d-block" title="C.P.">
            <i class="fa fa-check-circle text-primary"></i>
            C.P.
          </label>
          <input v-model="domicilio.cp" type="number" name="cp" id="cp" class="form-control" max="5">
        </div>

      </div>

      <div class="row justify-content-center">
        <div class="col-sm-3 form-group">
          <label for="calle" class="form-label text-truncate d-block" title="Calle">
            <i class="fa fa-check-circle text-primary"></i>
            Calle
          </label>
          <input v-model="domicilio.calle" type="text" name="calle" id="calle" class="form-control">
        </div>

        <div class="col-sm-3 form-group">
          <label for="numero_exterior" class="form-label text-truncate d-block" title="Número Exterior">
            <i class="fa fa-check-circle text-primary"></i>
            Número exterior
          </label>
          <input v-model="domicilio.numero_exterior" type="number" name="numero_exterior" id="numero_exterior" class="form-control" max="5">
        </div>

        <div class="col-sm-3 form-group">
          <label for="numero_interior" class="form-label text-truncate d-block" title="Número Interior">
            Número interior
          </label>
          <input v-model="domicilio.numero_interior" type="text" name="numero_interior" id="numero_interior" class="form-control" max="5">
        </div>

        <div class="col-sm-3 form-group">
          <label for="colonia" class="form-label text-truncate d-block" title="Colonia">
            <i class="fa fa-check-circle text-primary"></i>
            Colonia
          </label>
          <input v-model="domicilio.colonia" type="text" name="colonia" id="colonia" class="form-control">
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-sm-12 form-group">
          <label for="ubicacion" class="form-label text-truncate d-block" title="Ubicación del inmueble (link de ubicación google maps)">
            <i class="fa fa-check-circle text-primary"></i>
            Ubicación del domicilio <small class="text-muted">(link de ubicación google maps)</small>
          </label>
          <input v-model="domicilio.ubicacion" type="text" name="ubicacion" id="ubicacion" class="form-control" placeholder="https://maps.app.goo.gl/3u2HVvEMETQWxeGE6">
        </div>
      </div>
    </div>


    <div class="footer mb-4">
      <div class="row justify-content-end">
        <div class="col-auto"><button class="btn btn-danger" @click="cancelar"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
        <div class="col-auto"><button type="button" class="btn btn-success" @click="guardar" id="btn-crear" name="btn-crear"><i class='fa fa-save mr-sm-1'></i>Guardar
          {{ !domicilio.id ? '' : 'cambios' }}</button></div>
      </div>
    </div>


  </Modal>

</template>

<script>


import Modal from "@/components/Modal.vue";
import domiciliosApi from "@/apps/garantias_app/api/personas/domicilios.api";
import catalogosApi from "@/apps/garantias_app/api/catalogos/catalogos.api";
export default {
  name: "FormularioDomicilio"
  ,components: {Modal}
  ,props: {
    persona_id: null,
    domicilio_prop: { // Si la dirección es de una persona o garantia, el valor puede ser: persona o garantia
      type: Object,
      required: false,
      default() {
        return {
          id: null
          ,pais: 'México'
          ,estado: null
          ,municipio: null
          ,cp: null
          ,calle: null
          ,numero_interior: null
          ,numero_exterior: null
          ,colonia: null
          ,ubicacion: null
        }
      }
    }
  }
  ,data() {
    return {
      paises: ['México']
      ,estados: []
      ,municipios:[]
      ,domicilio: {
        pais: 'México'
        ,estado: null
        ,municipio: null
        ,cp: null
        ,calle: null
        ,numero_interior: null
        ,numero_exterior: null
        ,colonia: null
        ,ubicacion: null
      }
    }
  }
  ,async mounted() {

    await  this.cargar_estados();

    if (this.editar_domicilio){
      this.domicilio = this.domicilio_prop
    }
  }
  ,computed:{
    editar_domicilio(){
      return (this.domicilio_prop.hasOwnProperty('id') && typeof this.domicilio_prop.id == "string");
    }
  }
  ,methods:{
    async cargar_estados(){
      if (!this.solo_lectura) {
        const request = await catalogosApi.estados();
        this.estados = request.data
      }
    },
    async cargar_municipios(){
      let estado = this.estados.find(item  => item.estado === this.domicilio.estado);
      const request =  await catalogosApi.munincipios(estado.id);
      this.municipios = request.data
    },
    cancelar(){
      this.$emit('cancelar')
    }
    ,async guardar(){
      let isValid = await this.$validator({
        calle: !this.domicilio.calle ? 'required' : 'max:255',
        numero_exterior: !this.domicilio.numero_exterior ? 'required' : 'max:5',
        numero_interior: !this.domicilio.numero_interior ? '' : 'max:5',
        colonia: !this.domicilio.colonia ? 'required' : 'max:255',
        cp: !this.domicilio.cp ? 'required' : 'max:5',
        pais: !this.domicilio.pais ? 'required' : 'max:255',
        estado: !this.domicilio.estado ? 'required' : '',
        municipio: !this.domicilio.municipio ? 'required' : 'max:255',
        ubicacion: !this.domicilio.ubicacion ? 'required' : 'max:255',
      })

      if (isValid) {

        try{
        //Validar si es un nuevo domicilio o edición
        if (this.editar_domicilio) {

          const request_editar =  await domiciliosApi.editar(this.persona_id,this.domicilio.id, this.domicilio)
          this.$emit('guardar', request_editar.data)

          this.$helper.showMessage('Editar dirección', 'Dirección actualizado exitosamente.', 'success', 'alert')


        }else {
          const request_crear =  await domiciliosApi.crear(this.persona_id,this.domicilio)
          this.$emit('guardar', request_crear.data)

          this.$helper.showMessage('Agregar dirección', 'Dirección creado exitosamente.', 'success', 'alert')
        }

        } catch (e) {
          this.$log.error('Error', e);
          if (e.response && e.response.status !== 400)
            this.$helper.showMessage( (!this.editar_domicilio ? 'Guardar' : 'Editar')+ ' dirección', 'Ocurrio un error al guardar dirección, favor de reportarlo al administador.', 'error', 'alert')
        }

      }
    }
  }
  ,watch: {
    'domicilio.estado': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {

          if (oldValue != null)
            this.domicilio.municipio = null

          this.cargar_municipios()
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
