import garantiasAdminApi from "../garantias_admin.api";
import utils from "@/helpers/utilidades";

export default {

  index(persona) {
    return garantiasAdminApi.api.get(`${garantiasAdminApi.baseURL}/personas/${persona}/domicilios`);
  },

  obtener(persona,domicilio) {
    return garantiasAdminApi.api.get(`${garantiasAdminApi.baseURL}/personas/${persona}/domicilios/${domicilio}`)
  },

  crear(persona,payload) {
    return garantiasAdminApi.api.post(`${garantiasAdminApi.baseURL}/personas/${persona}/domicilios`, payload)
  },

  editar(persona,domicilio, payload) {
    return garantiasAdminApi.api.put(`${garantiasAdminApi.baseURL}/personas/${persona}/domicilios/${domicilio}`, payload)
  },

  eliminar(persona, domicilio) {
    return garantiasAdminApi.api.delete(`${garantiasAdminApi.baseURL}/personas/${persona}/domicilios/${domicilio}`)
  },

}